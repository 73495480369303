import PageContent from "../components/PageContent";
import Button from "../components/Button";
import i18n from "../i18n";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import DcCertificateAPI from "../api/DcCertificate";
import {BeatLoader} from "react-spinners";
import BoxContent from "../components/BoxContent";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import Link from "../components/Link";
import {removeSession} from "../utils/sessionData";

const LoginPage = () => {
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.serverError)
  const [hasErros, setHasErrors] = useState(false)
  const [spinnerLoginVidaas, setSpinnerLoginVidaas] = useState(false);
  const [spinnerLoginA3, setSpinnerLoginA3] = useState(false);

  const host = process.env.REACT_APP_DC_SSL_HOST;
  const pageUrl = process.env.REACT_APP_DC_SSL_PAGE_URL;
  const callbackUrl = process.env.REACT_APP_DC_SSL_CALLBACK_URL;
  const apikey = process.env.REACT_APP_DC_SSL_APIKEY;
  const customerId = process.env.REACT_APP_DC_SSL_CUSTOMER_ID;

  const sslAuthenticatorUrl = `${host}/v2/ssl/authenticator?pageUrl=${pageUrl}&callbackUrl=${callbackUrl}&apikey=${apikey}&customerId=${customerId}`;

  const mutationAuthenticateUrl = useMutation({
    mutationKey: ['authenticateUrl'],
    mutationFn: async () => {
      // @ts-ignore
      return await DcCertificateAPI.v1.authenticateUrl()
    },
    onMutate: () => {
      setSpinnerLoginVidaas(true)

      removeSession();
    },
    onSuccess: (response: any) => {
      console.log("muationAuthenticateUrl onSuccess: ", response)

      setHasErrors(false)
      setSpinnerLoginVidaas(false)

      window.open(response.data.authenticateUrl, "_self");
    },
    onError: (error: any) => {
      console.error("muationAuthenticateUrl onError: ", error)

      setHasErrors(true)
      setSpinnerLoginVidaas(false)

      if (error.response.status >= 400) setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
    }
  })

  const loginA3 = () => {
    setSpinnerLoginA3(true);

    removeSession();
  }

  return (
    <PageContent>
      {
        hasErros && (<Text className={"error bg-error"}>
          <span><TfiInfoAlt/><span>{mainErrorMessage}</span></span>
        </Text>)
      }

      <BoxContent>
        <h3>{i18n.ptBr.Pages.LoginPage.title}</h3>

        <p>{i18n.ptBr.Pages.LoginPage.definition}</p>

        <Button className={"btn-vidaas"} onClick={() => mutationAuthenticateUrl.mutate()}>
          {
            spinnerLoginVidaas ? (
              <BeatLoader color="#ffffff" loading={spinnerLoginVidaas} size={10}/>
            ) : (i18n.ptBr.Components.Buttons.loginVidaas)
          }
        </Button>

        <p><strong>{i18n.ptBr.Pages.LoginPage.or}</strong></p>

        <Link
          onClick={() => loginA3()}
          className={"btn-vcert"}
          rel="noopener noreferrer"
          to={sslAuthenticatorUrl}>
            {
              spinnerLoginA3 ? (
                <BeatLoader color="#ffffff" loading={spinnerLoginA3} size={10}/>
              ) : (i18n.ptBr.Components.Buttons.loginA3)
            }
        </Link>
      </BoxContent>
    </PageContent>
  )
}

export default LoginPage